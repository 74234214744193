
@keyframes slideIn {
  0% {
    transform: translateX(-50%) translateY(100%);
  }
  50% {
    transform: translateX(-50%) translateY(0);
  }
  80% {
    transform: translateX(-50%) translateY(-15%);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
}