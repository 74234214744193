.id-card .card-body {
  padding: 2rem !important;
}

.id-card {
  position: relative;
}

@media screen and (max-width: 768px) {
  .id-card-container {
      display: none !important;
  }
}

/* Default styles for smallest screens */
.id-card {
  width: 240px;
}

.id-card-img {
  display: block;
  width: 280px;
  object-fit: cover;
}
.id-card-content {
  top: 16%;
  left: 50%;
  transform: translateX(-50%);
  color: #898a8e;
  z-index: 1;
  width: 100%;
  height: 70%;
  display: flex;
  padding: 0 1rem;
  flex-direction: column;
  justify-content: space-between;
}

.first-name {
  color: #898a8e;
  top: 55px;
}

.last-name {
  color: #898a8e;
  top: 90px;
}

/* Media Queries for Scaling */

/* Slightly larger screens */
@media screen and (min-width: 1400px) {
  .id-card {
    width: 260px;
  }
  .id-card-img {
    width: 300px;
  }
  .id-card-content {
    top: 16%;
  }
}

/* Medium screens */
@media screen and (min-width: 1800px) {
  .id-card {
    width: 280px;
  }
  .id-card-img {
    width: 320px;
  }
  .id-card-content {
    top: 18%;
  }
}

/* Larger screens */
@media screen and (min-width: 2000px) {
  .id-card {
    width: 320px;
  }
  .id-card-img {
    width: 360px;
  }
  .id-card-content {
    top: 19%;
  }
}

/* Extra-large screens */
@media screen and (min-width: 2400px) {
  .id-card {
    width: 340px;
  }
  .id-card-img {
    width: 380px;
  }
  .id-card-content {
    top: 19%;
  }
}

/* Ultra-large screens */
@media screen and (min-width: 2800px) {
  .id-card {
    width: 380px;
  }
  .id-card-img {
    width: 420px;
  }
  .id-card-content {
    top: 20%;
  }
  .first-name {
    top: 60px;
  }
  .last-name {
    top: 100px;
  }
}

/* Maximum scaling for 4K displays */
@media screen and (min-width: 3200px) {
  .id-card {
    width: 400px;
  }
  .id-card-img {
    width: 440px;
  }
  .id-card-content {
    top: 20%;
  }
  .first-name {
    top: 65px;
  }
  .last-name {
    top: 105px;
  }
}
