input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.text-info {
    color: #6f4bd1 !important;
}

#global-check-loader {
    padding-bottom: 40vh !important;
}

@media  screen and (max-width: 1000px) {
    .id-card {
        order: 2;
    }

    .form-card {
        order: 1;
    }
}