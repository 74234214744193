#form-payment > div:nth-child(1) .form-control {
  padding: 0.75rem !important;
}

.btn {
  display: inline-block;
  border: 0.2px solid #fff;
  border-radius: 0.2rem;
  padding: 0.6rem 1rem;
  font-size: 0.9rem;
  font-weight: normal;
  cursor: pointer;
  text-transform: capitalize;
  margin: 0;
}

.btn-primary {
  background-color: #6f4bd1;
  color: #fdfcff;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: all 300ms ease-in-out;
}

.btn-primary:hover {
  background-color: #6f4bd1;
  color: #f8f3fd;
  box-shadow: 0 4px 12px rgba(111, 75, 209, 0.2);
}


.form-control {
  border: 1px solid #e0e0e0 !important;
  padding: 0.7rem 1rem !important;
  border-radius: 6px;
  background-color: #fff !important;
  transition: border-color 0.3s ease;
}

.error-message {
  color: #d14b4b;
  font-size: 14.5px;
  margin-top: 4px !important;
  margin-left: 4px !important;
}

.input-group .form-control.focused {
  border-radius: 8px;
  border: 1px solid #6f4bd1 !important;
  box-shadow: 0 0 0 3px rgba(111, 75, 209, 0.3) !important;
  background-image: none !important;
}

.input-group .form-control.error, .input-group .form-control.error:focus {
  border-radius: 8px;
  border: 1px solid #d14b4b !important;
  box-shadow: 0 0 0 3px rgba(209, 75, 75, 0.3) !important;
  background-image: none !important;
}

.login-form-check-input{
  margin-top: 0.25rem !important;
}

.form-check {
  padding-left: 0 !important;
}

.help-text {
  font-size: 0.85rem;
}

.valid {
  color: #01b26d;
}

.invalid {
  color: #939393;
}

#activateFreeBtn{
  color: var(--text-brand-secondary, #65319B);
  
  /* text xs/medium */
  font-family: var(--Font-Family-family, "Open Sauce Sans");
  font-size: var(--Font-Size-text-xs, 12px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Line-Height-text-xs, 16px);
  border-radius: 8px;
    border: 1px solid var(--border-brand-secondary-alt, #F0E7FA);
  /* 133.333% */
}