.card {
  border-radius: 16px !important;
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.078);
  background-color: #f9fafb;
}

.card .card-header {
  border-bottom: 1px solid #dee2e6;
  padding: 2rem;
  margin: 0 !important;
}

.card .card-body {
  padding: 2rem 4rem;
}

@media only screen and (max-width: 540px) {
  .card .card-body {
    padding: 1rem 2rem;
  }
}