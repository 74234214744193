

.main-content > div.container-fluid {
  padding: 0 !important;
}

.card.id-card {
  border-radius: 16px !important;
}

.h-line-1 {
  position: absolute;
  top: 3em;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #36393f;
}

.h-line-2 {
  position: absolute;
  bottom: -2em;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #36393f;
}

.input-group .form-control:focus {
  border-radius: 6px;
  border: 1px solid var(--primary-500) !important;
  box-shadow: 0 0 0 3px rgba(111, 75, 209, 0.3) !important;
  background-image: none !important;
}

.input-group .form-control.is-invalid, .input-group .form-control.is-invalid:focus {
  border-radius: 6px;
  border: 1px solid var(--error-600) !important;
  box-shadow: 0 0 0 3px rgba(209, 75, 75, 0.3) !important;
  background-image: none !important;
}


.input-group {
  position: relative !important;
}

.field-icon {
  /* position: absolute !important;
  top: 50px !important;
  right: 10px;
  transform: translateY(-50%);
  font-size: 1rem !important;
  cursor: pointer; */
}

.is-invalid ~ .field-icon {
  right: 10px;
}