.responsive-plan-card {
  width: 460px !important;
  max-width: 100%;
  height: 580px !important;
  max-height: 100%;
  /* overflow: scroll; */
}

@media screen and (max-width: 780px) {
  .responsive-plan-card {
    height: 480px !important;
  }
}

.plans-tab {
  width: 460px !important;
  max-width: 100%;
}

.plans-tab button {
  background-color: #f3f4f7;
}

.pay-card .img img {
  width: 350px;
  display: block;
  max-width: 100%;
}

.form-switch .form-check-input {
  margin-top: 0.2em !important;
  width: 2.75rem;
}

.form-switch .form-check-input::after {
  top: 0.3px;
  left: 1px;
}

.form-switch.is-focused .form-check-input::after {
  top: -0.6px;
  left: -1px;
}

.pay-toggle-form {
  height: 80px !important;
  max-height: 100%;
}

.pay-toggle-form .form-switch .form-check-input.is-focused:checked::after {
  transform: translate(21px, -1px) !important;
}

.pay-toggle-form .form-switch .form-check-input:checked::after {
  transform: translate(21px, 0px) !important;
}

@media screen and (max-width: 540px) {
  .pay-card {
    /* order: 3; */
    display: none !important;
  }

  .pay-toggle-form {
    order: 1;
  }

  .discount-badge {
    order: 2;
  }

  .plan-card {
    order: 1;
  }
}

#free_trial_button {
  border-radius: 8px;
  border: 1px solid var(--border-brand-secondary-alt, #F0E7FA);
  background: var(--background-brand-primary, #FBF9FD);
  color: var(--text-brand-secondary, #65319B);

  /* text xs/medium */
  font-family: var(--Font-Family-family, "Open Sauce Sans");
  font-size: var(--Font-Size-text-xs, 12px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Line-Height-text-xs, 16px);
  /* 133.333% */
}