.mobile-preview .form-row {
  display: flex !important;
  flex-direction: column !important;
  font-size: 12px;
}

.mobile-preview .form-row .form-field {
  width: 100% !important;
  margin-bottom: 12px;
}

.mobile-preview .form-row .form-field input+span {
  top: 44px !important;
}

.mobile-preview .input-group {
  width: 100% !important;
}


/* Input */
.mobile-preview input[type="text"], .mobile-preview input[type="password"], .mobile-preview input[type="email"], .mobile-preview input[type="string"], .mobile-preview input[type="date"], .mobile-preview select, .mobile-preview .input-group input {
  font-size: 0.875rem !important;
  border-radius: 8px !important;
}

.mobile-preview input::placeholder{
  font-size: 0.875rem !important;
}

.mobile-preview .form-field label, .mobile-preview .input-group label {
  font-size: 0.75rem !important;
  margin-bottom: 4px !important;
  margin-left: 0 !important;
  color: #4B5563;
}