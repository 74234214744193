/* Notification */

@keyframes slide {
  0% {
      transform: translateX(100%);
  }
  50% {
      transform: translateX(-40px);
  }
  80% {
      transform: translateX(-20px);
  }
  100% {
      transform: translateX(-40px);
  }
}