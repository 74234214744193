.responsive-payment-confirmation-card {
  /* width: 460px !important; */
  max-width: 100%;
  height: 540px !important;
  max-height: 100%;
  /* overflow: scroll; */
}

@media screen and (max-width: 780px) {
  .responsive-payment-confirmation-card {
    height: 460px !important;
  }
}

.pay-card .img img {
  width: 350px;
  display: block;
  max-width: 100%;
}

.plan-final-amount {
  height: 40px;
  max-height: 100%;
}

.plan-tax-amount p, .plan-due-today p{
  font-size: 0.8rem;
}

@media  screen and (max-width: 540px) {
  .pay-card {
    /* order: 3; */
    display: none !important;
  }

  .pay-toggle-form {
    order: 1;
  }

  .discount-badge {
    order: 2;
  }

  .plan-card {
      order: 1;
  }
}
