/* @font-face {
  font-family: 'Open Sauce Sans';
  src: url('./assets/fonts/OpenSauceSans-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
} */

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('./assets/fonts/OpenSauceSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('./assets/fonts/OpenSauceSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('./assets/fonts/OpenSauceSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('./assets/fonts/OpenSauceSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}


:root {
  /* Font Family */
  --font-family: "Open Sauce Sans", "Poppins", sans-serif;

  /* Colors */
  --primary-clr: #fff;
  --secondary-clr: #0e1219;
  --nav-active-clr: #f4f5f6;
  --icon-clr: #434b5b;

  /* Primary Colors */
  --primary-25: #fdfcff;
  --primary-50: #fbf9fd;
  --primary-100: #f8f3fd;
  --primary-200: #f0e8fa;
  --primary-300: #e3d1f6;
  --primary-400: #c9a8ec;
  --primary-500: #6f4bd1;
  --primary-600: #8a42d5;
  --primary-700: #7643ab;
  --primary-800: #65319b;
  --primary-900: #5c3484;
  --primary-950: #512e74;

  /* Secondary Colors */
  --secondary-25: #fcfcfd;
  --secondary-50: #f9fafb;
  --secondary-100: #f3f4f7;
  --secondary-200: #f1f2f4;
  --secondary-300: #e1e4e9;
  --secondary-400: #9ba1b2;
  --secondary-500: #687083;
  --secondary-600: #4a5365;
  --secondary-700: #374052;
  --secondary-800: #1a212f;
  --secondary-900: #111827;
  --secondary-950: #0c111c;

  /* Error Colors */
  --error-25: #fefbfb;
  --error-50: #fef8f8;
  --error-100: #fef1f1;
  --error-200: #fde5e4;
  --error-300: #fccdcb;
  --error-400: #fa9f9b;
  --error-500: #f64640;
  --error-600: #d83e37;
  --error-700: #bf372f;
  --error-800: #a7302a;
  --error-900: #932a24;
  --error-950: #812522;

  /* Success Colors */
  --success-25: #fafdfc;
  --success-50: #f6fcf9;
  --success-100: #ecf9f4;
  --success-200: #dbf4ea;
  --success-300: #baead7;
  --success-400: #7ad7b3;
  --success-500: #01b26d;
  --success-600: #00b26d;
  --success-700: #008a54;
  --success-800: #01794a;
  --success-900: #016a41;
  --success-950: #005d39;

  /* Warning Colors */
  --warning-25: #fefdfb;
  --warning-50: #fefbf7;
  --warning-100: #fef7ef;
  --warning-200: #feefe1;
  --warning-300: #fee1c6;
  --warning-400: #fdc690;
  --warning-500: #fc9029;
  --warning-600: #dd8025;
  --warning-700: #c37121;
  --warning-800: #ac631d;
  --warning-900: #97571b;
  --warning-950: #864d18;

  /* Font Sizes */
  --font-size-lg: 1.125rem;
  --font-size-md: 1rem;
  --font-size-sm: 0.875rem;

  /* Padding Sizes */
  --padding-lg: 0.8rem 1.6rem;
  --padding-md: 0.6rem 1.2rem;
  --padding-sm: 0.5rem 1rem;

  /* Border Radius */
  --border-radius-lg: 0.2rem;
  --border-radius-md: 0.2rem;
  --border-radius-sm: 0.2rem;

  /* Colors */
  --color-bg-sidebar: #fff;
  --color-text-sidebar: #0f141d;
  --color-border-sidebar: #d8d8d8;
  --color-bg-nav-item: #f6f2ff;
  --color-text-nav-link: #0f141d;
  --color-icon: #868d9f;
  --color-nav-heading: #868d9f;
  --color-bg-scrollbar: #e3e3e3;
  --color-bg-scrollbar-hover: #b1b1b1;

  /* Font sizes */
  --font-size-lg: 1.2rem;
  --font-size-md: 1rem;
  --font-size-sm: 0.85rem;
  --font-size-xs: 0.75rem;

  /* Dimensions */
  --sidebar-width-expanded: 280px;
  --sidebar-width-collapsed: 85px;
  --toggle-btn-size: 30px;
}

body {
  font-family: var(--font-family) !important;
  overflow: auto;
}

.main-title, .sub-heading {
  font-family: var(--font-family) !important;
}

.main-title {
  font-size: 1.5rem !important;
}

.btn-secondary {
  background-color: var(--secondary-200);
  font-weight: normal;
  box-shadow: none;
  background-image: none;
  border: 1px solid var(--secondary-200);
  color: var(--secondary-700);
  transition: all 200ms ease-in-out;
}

.btn-secondary i {
  color: var(--secondary-700);
}

.btn-secondary-sm {
  padding: var(--padding-sm);
  font-size: var(--font-size-sm);
  border-radius: var(--border-radius-sm);
}

.btn-secondary:hover {
  background-color: var(--secondary-300);
  color: var(--secondary-700);
  opacity: 1;
}

.btn-link {
  text-decoration: none !important;
  padding: 0 !important;
  color: #6f4bd1 !important;
  cursor: pointer !important;
}

.btn-link p {
  font-weight: 500 !important;
}

.form-control.is-invalid {
  line-height: 1.5 !important;
}

.form-card {
  border-radius: 16px !important;
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.078);
  background-color: #f9fafb;
}

.fa-eye {
  z-index: 999;
}

.text-danger {
  font-size: 0.85rem;
}

.main-title {
  font-weight: 700 !important;
}

.input-group {
  margin-bottom: 1.5rem;
}

.input-group label {
  margin-left: 0 !important;
  margin-bottom: 4px !important;
  color: #4B5563;
}

input[type="text"], input[type="password"], input[type="email"], input[type="string"], input[type="search"], input[type="date"], select {
  padding: 0.75rem;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback), .input-group input {
  border-radius: 8px !important;
  margin-top: 0.2rem;
}

/*
input[type="text"].form-control, input[type="password"].form-control, input[type="email"].form-control, input[type="string"].form-control, input[type="search"].form-control {
  font-size: 0.85rem;
} */

input#mobile_id {
  padding-left: 100px !important;
}


.otp-inputs {
  display: flex;
  justify-content: space-between;
}

.otp-input {
  font-size: 0.875rem;
  flex: 1 1 auto;
  max-width: 50px;
  min-width: 30px;
  height: 50px;
  margin: 0 2px;
  text-align: center;
}

.otp-input:nth-child(1) {
  margin-left: 0 !important;
}

.otp-input:last-child {
  margin-right: 0 !important;
}

.btn.btn-link {
  font-weight: 600 !important;
}

.iti__country-list {
  z-index: 999;
}