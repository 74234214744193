.product-config .form-switch .form-check-input {
  height: 1.375rem;
  width: 2.75rem;
  margin-top: 0;
  border: none !important;
}

.product-config li {
  list-style-type: none;
}

.product-config .form-switch .form-check-input:after {
  left: 1px;
  top: 1px;
}

.product-config .form-switch.is-focused .form-check-input:after {
  left: 1px;
  top: 1px;
}

.product-config button {
  border-radius: 4px;
  padding: 0.5rem 1rem 0.5rem 0.75rem !important;
  font-size: 0.875rem !important;
  font-weight: 500;
}

.modal-body img {
  width: inherit !important;
}