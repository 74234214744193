input:focus {
  border: 1px solid #6f4bd1 !important;
}

input.is-invalid:focus {
  border: 1px solid #f64640 !important;
}

/* Default font-size for all browsers */
input[type="text"].form-control::placeholder, input[type="password"].form-control::placeholder, input[type="email"].form-control::placeholder, input[type="string"].form-control::placeholder, input[type="search"].form-control::placeholder {
  font-size: 1rem;
}

/* Small devices */
@media (max-width: 768px) {
  input[type="text"].form-control::placeholder, input[type="password"].form-control::placeholder, input[type="email"].form-control::placeholder, input[type="string"].form-control::placeholder, input[type="search"].form-control::placeholder {
      font-size: 0.875rem;
  }
}

/* Safari-specific font-size override */
@supports (-webkit-touch-callout: none) {
  input[type="text"].form-control::placeholder, input[type="password"].form-control::placeholder, input[type="email"].form-control::placeholder, input[type="string"].form-control::placeholder, input[type="search"].form-control::placeholder {
      font-size: 1rem;
  }
}

.login-card .img {
  /* width: 35px; */
  margin-bottom: 10px;
}

.login-card .img img {
  display: block;
  max-height: 40px;
  border-radius: 2px;
}

/* .login-card .img {
  width: auto;
  height: 30px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-card .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.btn {
  display: inline-block;
  border: 0.2px solid #fff;
  border-radius: 0.2rem;
  padding: 0.6rem 1rem;
  font-size: 0.9rem;
  font-weight: normal;
  cursor: pointer;
  text-transform: capitalize;
  margin: 0;
}

.btn-primary {
  background-color: #6f4bd1;
  color: #fdfcff;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: all 300ms ease-in-out;
}

.btn-primary:hover {
  background-color: #6f4bd1;
  color: #f8f3fd;
  box-shadow: 0 4px 12px rgba(111, 75, 209, 0.2);
}

input-group {
  position: relative;
}

#password-error, #email-error {
  display: none;
}

.form-control.is-invalid {
  background-image: none;
}

i.field-icon[toggle="#com-pass"] {
  /* position: absolute;
  right: 10px;
  top: 72% !important;
  transform: translateY(-50%);
  cursor: pointer; */
}

.error-text {
  color: #d83e37;
}


.input-group .form-control:focus {
  border-radius: 8px;
  border: 1px solid var(--primary-500) !important;
  box-shadow: 0 0 0 3px rgba(111, 75, 209, 0.3) !important;
  background-image: none !important;
}

.input-group .form-control.is-invalid, .input-group .form-control.is-invalid:focus {
  border-radius: 8px;
  border: 1px solid var(--error-600) !important;
  box-shadow: 0 0 0 3px rgba(209, 75, 75, 0.3) !important;
  background-image: none !important;
}

.login-form-check-input{
  margin-top: 0.25rem !important;
}

.form-check {
  padding-left: 0 !important;
}