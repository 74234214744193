.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* @keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  margin: auto;
} */


/* .spinner {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side,#6f4bd1 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#6f4bd1);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: spin 1s infinite linear;
}

@keyframes spin{
  100%{transform: rotate(1turn)}
} */


/* Loader container styling */
.spinner {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #6f4bd1 94%, transparent) top/8px 8px no-repeat,
    conic-gradient(transparent 30%, #6f4bd1);
  -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - 8px), #000 0);
  animation: spin 2s infinite cubic-bezier(0.1, 0.8, 0.4, 1.2);
}

/* Enhanced Spin Animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(90deg);
  }
  25% {
    transform: rotate(120deg);
  }
  35% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(240deg);
  }
  65% {
    transform: rotate(300deg);
  }
  75% {
    transform: rotate(330deg);
  }
  85% {
    transform: rotate(340deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

