.auth-background-container {
  background-color: #f0f0f0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  overflow-x: hidden;
}

.auth-background-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
}

.auth-content-wrapper {
  position: relative;
  z-index: 1;
}

.bg-shape {
  opacity: 0.2;
  filter: blur(100px);
  border-radius: 100%;
  width: 300px;
  height: 300px;
  position: relative;
}

.bg-shape-1 {
  background-color: #6f4bd1;
}

.bg-shape-2 {
  background-color: #01b26d;
  margin-left: 20px;
}

.bg-shape-3 {
  background-color: #fc9029;
  margin-top: -40px;
}

.top-shapes {
  display: flex;
  justify-content: center;
  margin-bottom: -40px;
}

.bg-shape-3 {
  margin-top: 50px;
}
